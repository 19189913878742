import React, { useEffect } from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { B1White, colors, H1White, standardWrapper } from "../../styles/helpers"
import { Link } from "gatsby"
import BGImg from "gatsby-background-image"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const ContentWithImageLinks = ({ data }) => {
  const imageDisplay = getImage(
    data.icon.localFile.childImageSharp.gatsbyImageData
  )
  const imageAlt = data.icon.altText

  useEffect(() => {
    const boxOne = document.querySelector(".image-link-1")
    const boxTwo = document.querySelector(".image-link-2")
    const boxThree = document.querySelector(".image-link-3")
    const boxFour = document.querySelector(".image-link-4")

    if (boxOne && boxTwo && boxThree && boxFour) {
      gsap.set(boxOne, {
        autoAlpha: 0,
        y: -200,
      })

      gsap.set(boxTwo, {
        autoAlpha: 0,
        x: 200,
      })

      gsap.set(boxThree, {
        autoAlpha: 0,
        x: -200,
      })

      gsap.set(boxFour, {
        autoAlpha: 0,
        y: 200,
      })

      gsap
        .timeline({
          scrollTrigger: {
            trigger: "#links-wrapper",
            markers: false,
            start: "top 50%",
            toggleActions: "play none none none",
          },
        })
        .to([boxOne, boxTwo, boxThree, boxFour], {
          autoAlpha: 1,
          x: 0,
          y: 0,
          ease: "power4.out",
          stagger: {
            amount: 0.6,
          },
        })
    }
  }, [])

  return (
    <ContentWithImageLinksStyled>
      <div className="wrapper">
        <div className="banner">
          <div className="banner__title">
            <h2>{data.title}</h2>
            <div className="icon">
              <div className="icon__image">
                <GatsbyImage
                  image={imageDisplay}
                  alt={imageAlt}
                  layout="fixed"
                />
              </div>
            </div>
          </div>
          <div
            className="banner__content"
            dangerouslySetInnerHTML={{ __html: data.content }}
          />
        </div>

        <div className="links" id="links-wrapper">
          {data.imageLinks.map((link, index) => {
            const linkImage = link.image.localFile.childImageSharp.fluid
            return (
              <LinkStyled className={`image-link-${index + 1}`} key={index}>
                <Link to={`/${link.linkSlug}`}>
                  <div className="linkTitle">
                    <p>{link.title}</p>
                  </div>
                  <div className="linkOverlay" />
                  <div className="linkImage">
                    <BGImg tag="div" fluid={linkImage} alt={""} />
                  </div>
                </Link>
              </LinkStyled>
            )
          })}
        </div>
      </div>
    </ContentWithImageLinksStyled>
  )
}

const ContentWithImageLinksStyled = styled.section`
  .wrapper {
    ${standardWrapper};
  }

  .banner {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 2rem);
    margin: auto 1rem;
    padding: 4rem 6rem;
    background-color: ${colors.colorAccent};

    &__title {
      width: 100%;

      @media (min-width: 768px) {
        width: calc(25%);
      }

      h2 {
        ${H1White};
        font-weight: normal;
        text-align: center;
      }

      .icon {
        width: 100%;

        &__image {
          width: 100%;
          max-width: 11rem;
          margin: 2rem auto;
        }
      }
    }

    &__content {
      width: 100%;

      @media (min-width: 768px) {
        width: calc(75%);
      }

      p {
        ${B1White};
      }
    }
  }

  .links {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
`

const LinkStyled = styled.div`
  position: relative;
  width: calc(100%);
  height: 35rem;
  margin: 1rem auto;
  transition: all ease-out 0.3s;

  @media (min-width: 768px) {
    width: calc(50% - 2rem);
    margin: 1rem;
    height: 40rem;
  }

  .linkTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 5rem 7.5rem;
    align-items: center;
    position: relative;
    z-index: 10;

    p {
      ${H1White};
      margin: 0;
      text-align: center;
    }
  }

  .linkOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0.8;
    transition: all ease-out 0.3s;
    background-color: rgba(77, 88, 113, 0.9);
  }

  .linkImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    div {
      width: 100%;
      height: 100%;
    }
  }

  &:hover {
    box-shadow: 0.5rem 0.5rem 0.5rem 0rem rgba(77, 88, 113, 0.9);
    .linkOverlay {
      opacity: 0;
    }
  }
`

export default ContentWithImageLinks
