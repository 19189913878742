import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { B1Slate, H2Teal, medWrapper } from "../../styles/helpers"

const getData = graphql`
  {
    sponsors: allWpSponsor {
      edges {
        node {
          title
          terms {
            nodes {
              name
              slug
            }
          }
          acfSponsors {
            fieldGroupName
            linkRequired
            logo {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 750)
                }
              }
            }
            url
          }
        }
      }
    }
  }
`

const DisplaySponsors = ({ data }) => {
  const sponData = useStaticQuery(getData)
  const sponsors = sponData.sponsors.edges

  if (!data.display) return null

  const keyFunders = sponsors.filter(
    sponsor => sponsor.node.terms.nodes[0].slug === "corporate-partners"
  )

  console.log("keyFunders", keyFunders)

  const accreditation = sponsors.filter(
    sponsor =>
      sponsor.node.terms.nodes[0].slug === "government-funding-partners"
  )

  const thankYou = sponsors.filter(
    sponsor => sponsor.node.terms.nodes[0].slug === "foundations"
  )

  console.log("thankYou", thankYou)

  return (
    <DisplaySponsorsStyled>
      <div className="wrapper">
        <div className="sponsorLevel">
          <div className="sponsorLevel__title">
            <h2>Government & Funding Partners</h2>
          </div>
          <div className="sponsorLevel__logos">
            {accreditation.map((acc, index) => {
              const imageDisplay = getImage(
                acc?.node?.acfSponsors?.logo?.localFile?.childImageSharp
                  ?.gatsbyImageData
              )
              const imageAlt = acc?.node?.acfSponsors?.logo?.altText

              if (!imageDisplay) return

              if (acc.node.acfSponsors.linkRequired) {
                return (
                  <Logo key={index}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={acc.node.acfSponsors.url}
                    >
                      <GatsbyImage
                        image={imageDisplay}
                        alt={imageAlt}
                        layout="fixed"
                      />
                    </a>
                  </Logo>
                )
              } else {
                return (
                  <Logo key={index}>
                    <GatsbyImage
                      image={imageDisplay}
                      alt={imageAlt}
                      layout="fixed"
                    />
                  </Logo>
                )
              }
            })}
          </div>
        </div>

        {keyFunders && keyFunders.length > 0 && (
          <div className="sponsorLevel">
            <div className="sponsorLevel__title">
              <h2>Corporate Supporters</h2>
            </div>
            <div className="sponsorLevel__logos">
              {keyFunders.map((keyFunder, index) => {
                const imageDisplay = getImage(
                  keyFunder?.node?.acfSponsors?.logo?.localFile?.childImageSharp
                    ?.gatsbyImageData
                )
                const imageAlt = keyFunder?.node?.acfSponsors?.logo?.altText

                if (!imageDisplay) return

                if (keyFunder.node.acfSponsors.linkRequired) {
                  return (
                    <Logo key={index}>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={keyFunder.node.acfSponsors.url}
                      >
                        <GatsbyImage
                          image={imageDisplay}
                          alt={imageAlt}
                          layout="fixed"
                        />
                      </a>
                    </Logo>
                  )
                } else {
                  return (
                    <Logo key={index}>
                      <GatsbyImage
                        image={imageDisplay}
                        alt={imageAlt}
                        layout="fixed"
                      />
                    </Logo>
                  )
                }
              })}
            </div>
          </div>
        )}

        {thankYou && thankYou.length > 0 && (
          <div className="sponsorLevel">
            <div className="sponsorLevel__title">
              <h2>Foundations</h2>
            </div>
            <div className="sponsorLevel__logos">
              {thankYou.map((thank, index) => {
                if (thank.node.acfSponsors.linkRequired) {
                  return (
                    <Logo key={index}>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={thank.node.acfSponsors.url}
                      >
                        <p>{thank.node.title}</p>
                      </a>
                    </Logo>
                  )
                } else {
                  return (
                    <Logo key={index}>
                      <p>{thank.node.title}</p>
                    </Logo>
                  )
                }
              })}
            </div>
          </div>
        )}
      </div>
    </DisplaySponsorsStyled>
  )
}

const DisplaySponsorsStyled = styled.section`
  .wrapper {
    ${medWrapper};
  }

  .sponsorLevel {
    width: 100%;
    margin: 4rem auto;

    h2 {
      ${H2Teal};
      text-align: center;
    }

    &__logos {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
    }
  }
`

const Logo = styled.div`
  width: calc((100% / 2) - 2rem);
  margin: 3rem 1rem;

  @media (min-width: 768px) {
    width: calc((100% / 4) - 6rem);
    margin: 2rem 3rem;
  }

  p {
    ${B1Slate};
    text-align: center;
  }
`

export default DisplaySponsors
