import React, { useEffect } from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  B1White,
  Btn1Green,
  colors,
  H1White,
  H2White,
  medWrapper,
} from "../../styles/helpers"
import { Link } from "gatsby"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const isBrowser = () => typeof window !== "undefined"

const ThreeColumnsGreen = ({ data }) => {
  useEffect(() => {
    if (!data.animateStatNumbers) return

    const items = document.querySelectorAll(".stat-counter")

    gsap
      .timeline({
        scrollTrigger: {
          trigger: "#three-columns-green",
          markers: false,
          start: "top 50%",
          toggleActions: "play none none none",
        },
      })
      .from(items, {
        textContent: 0,
        duration: 2.5,
        ease: "power1.in",
        snap: { textContent: 1 },
        stagger: {
          each: 0.25,
          onUpdate: function () {
            this.targets()[0].innerHTML = numberWithCommas(
              Math.ceil(this.targets()[0].textContent)
            )
          },
        },
      })

    function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  }, [])

  return (
    <ThreeColumnsGreenSection id="three-columns-green">
      <div className="wrapper">
        {data.title && (
          <div className="title">
            <h2>{data.title}</h2>
          </div>
        )}
        <div className="columnsWrapper">
          {data.columns.map((column, index) => {
            const imageDisplay = getImage(
              column.icon.localFile.childImageSharp.gatsbyImageData
            )
            const imageAlt = column.icon.altText

            return (
              <Column
                className={`column-green-${index}`}
                btnreq={column.buttonRequired}
                key={index}
              >
                <div className="icon">
                  <div className="icon__image">
                    <GatsbyImage
                      image={imageDisplay}
                      alt={imageAlt}
                      layout="fixed"
                    />
                  </div>
                </div>
                <div className="colTitle">
                  <h3>
                    {data.animateStatNumbers && (
                      <span className="stat-counter">{column.titleNumber}</span>
                    )}{" "}
                    {column.title}
                  </h3>
                </div>
                <div
                  className="calContent"
                  dangerouslySetInnerHTML={{ __html: column.content }}
                />
                {column.buttonRequired && (
                  <div className="colLink">
                    {column.linkRefresh && isBrowser() ? (
                      <button
                        onClick={() =>
                          window.location.replace(
                            `${process.env.GATSBY_BASE_URL}/${column.buttonSlug}`
                          )
                        }
                      >
                        {column.buttonText}
                      </button>
                    ) : (
                      <Link to={`/${column.buttonSlug}`}>
                        {column.buttonText}
                      </Link>
                    )}
                  </div>
                )}
              </Column>
            )
          })}
        </div>
        {data.bottomNotes && (
          <div className="notes">
            <p>{data.bottomNotes}</p>
          </div>
        )}
      </div>
    </ThreeColumnsGreenSection>
  )
}

const ThreeColumnsGreenSection = styled.section`
  background-color: ${colors.colorAccent};

  .wrapper {
    ${medWrapper};
  }

  .title {
    width: 100%;
    text-align: center;

    h2 {
      ${H1White};
    }
  }

  .columnsWrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
  }

  .notes {
    width: 100%;
    text-align: center;

    p {
      ${B1White};
      color: rgba(255, 255, 255, 0.54);
      font-style: italic;
    }
  }
`

const Column = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: ${props => (props.btnreq ? "10rem" : "0rem")};
  text-align: center;

  @media (min-width: 768px) {
    width: calc(33.333333% - 4rem);
    margin: 2rem;
  }

  .icon {
    width: 100%;

    &__image {
      width: 100%;
      max-width: 11rem;
      margin: 2rem auto;
    }
  }

  .colTitle {
    width: 100%;

    h3 {
      ${H2White};
    }
  }

  .calContent {
    width: 100%;

    p {
      ${B1White};
    }
  }

  .colLink {
    position: absolute;
    bottom: 2rem;
    left: 0;
    right: 0;
    width: 100%;
    margin: auto;
    text-align: center;

    a,
    button {
      ${Btn1Green};
      background-color: transparent;
    }
  }
`

export default ThreeColumnsGreen
