import React, { useEffect } from "react"
import styled from "styled-components"
import BGImg from "gatsby-background-image"
import { HIntroWhite } from "../../styles/helpers"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const HeroSectionOne = ({ data }) => {
  const heroImage = data.image.localFile.childImageSharp.fluid

  useEffect(() => {
    const contentBox = document.querySelector(".heroContent")

    gsap.set(contentBox, {
      autoAlpha: 0,
      y: 200,
    })

    gsap.timeline().add("start").to(
      contentBox,
      {
        autoAlpha: 1,
        y: 0,
        duration: 0.75,
        ease: "none",
      },
      "start+=0.5"
    )
  }, [])

  return (
    <HeroSectionOneStyled>
      <div className="heroContent">
        <div
          className="heroContent__content"
          dangerouslySetInnerHTML={{ __html: data.content }}
        />
      </div>
      <div className="heroImage">
        <BGImg tag="div" fluid={heroImage} />
      </div>
    </HeroSectionOneStyled>
  )
}

const HeroSectionOneStyled = styled.div`
  position: relative;
  height: 50rem;

  @media (min-width: 1025px) {
    height: 65rem;
  }

  .heroContent {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 7rem;
    bottom: 7rem;
    left: 0;
    width: 100%;
    padding: 2.5rem 5%;
    background-color: rgba(0, 59, 73, 0.9);
    z-index: 100;

    @media (min-width: 768px) {
      top: 7rem;
      bottom: 7rem;
      left: 7rem;
      width: 50rem;
    }

    @media (min-width: 1025px) {
      max-width: 110rem;
      padding: 2.5rem 5%;
    }

    &__content {
      p {
        ${HIntroWhite};
      }

      p:last-of-type {
        margin: 0;
      }
    }
  }

  .heroImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    div {
      width: 100%;
      height: 100%;
    }
  }
`

export default HeroSectionOne
