import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { graphql, Link, useStaticQuery } from "gatsby"
import {
  B1Slate,
  colors,
  HIntroGreen,
  medWrapper,
  Nav1Slate,
  Btn1Green,
} from "../../styles/helpers"

import NewArticle from "./NewArticle"
import SpinnerAnimation from "../Animations/SpinnerAnimation"

const getData = graphql`
  {
    posts: allWpPost(sort: { order: DESC, fields: date }) {
      edges {
        node {
          title
          id
          date
          slug
          categories {
            nodes {
              name
              slug
            }
          }
          acfNewsEvents {
            excerpt
            needsRefresh
            featuredImage {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1500) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }

    category: allWpCategory {
      edges {
        node {
          name
          slug
          id
        }
      }
    }
  }
`

const DisplayNewsEvents = ({ data }) => {
  const postsData = useStaticQuery(getData)
  const posts = postsData.posts.edges
  const categories = postsData.category.edges

  const DISPLAY_NUMBER = 7
  let postCount = 0

  const [activeCat, setActiveCat] = useState("all")

  const setNewActiveCat = cat => {
    setActiveCat(cat)
    setPostsStoreStore(prevState => {
      return {
        ...prevState,
        catChange: false,
      }
    })
  }

  const [postsStore, setPostsStoreStore] = useState({
    max: 0,
    current: 0,
    catPosts: [],
    display: [],
    loading: false,
    catChange: false,
  })

  useEffect(() => {
    setPostsStoreStore(prevState => {
      return {
        ...prevState,
        max: posts?.length,
        current: DISPLAY_NUMBER,
        catPosts: posts.filter(post => {
          if (
            activeCat !== "all" &&
            !post.node.categories.nodes.some(cat => {
              return cat.slug === activeCat
            })
          ) {
            return false
          } else {
            return true
          }
        }),
        display: posts.slice(0, DISPLAY_NUMBER),
        more: posts?.length > DISPLAY_NUMBER,
      }
    })
  }, [activeCat])

  useEffect(() => {
    setPostsStoreStore(prevState => {
      return {
        ...prevState,
        max: prevState.catPosts.length,
        display: prevState.catPosts.slice(0, DISPLAY_NUMBER),
        more: prevState.catPosts.length > DISPLAY_NUMBER,
      }
    })
  }, [postsStore.catPosts])

  const getMorePosts = () => {
    setPostsStoreStore(prevState => {
      return {
        ...prevState,
        current: prevState.current + DISPLAY_NUMBER,
        display: prevState.catPosts.slice(
          0,
          prevState.current + DISPLAY_NUMBER
        ),
        more: prevState.max > prevState.current + DISPLAY_NUMBER,
        loading: false,
      }
    })
  }

  const loadMorePostsHandler = () => {
    setPostsStoreStore(prevState => {
      return {
        ...prevState,
        loading: true,
      }
    })

    setTimeout(() => {
      getMorePosts()
    }, 2000)
  }

  const loadSetActiveCat = cat => {
    setPostsStoreStore(prevState => {
      return {
        ...prevState,
        catChange: true,
      }
    })

    setTimeout(() => {
      setNewActiveCat(cat)
    }, 2000)
  }

  return (
    <DisplayNewsEventsSection>
      <CatNav>
        <ul>
          <li>
            <button
              className={"all" === activeCat ? "activeCat" : ""}
              onClick={() => {
                loadSetActiveCat("all")
              }}
            >
              All
            </button>
          </li>
          {categories.map(cat => {
            return (
              <li key={cat.node.id}>
                <button
                  className={cat.node.slug === activeCat ? "activeCat" : ""}
                  onClick={() => {
                    loadSetActiveCat(cat.node.slug)
                    postCount = 1
                  }}
                >
                  {cat.node.name}
                </button>
              </li>
            )
          })}
        </ul>
      </CatNav>
      <div className="wrapper">
        <div className="articlesWrapper">
          {postsStore.display.map((post, index) => {
            const featuredImage =
              post.node.acfNewsEvents.featuredImage.localFile.childImageSharp
                .fluid
            const options = { year: "numeric", month: "long", day: "numeric" }
            const postDate = new Date(post.node.date).toLocaleDateString(
              undefined,
              options
            )

            if (
              activeCat !== "all" &&
              !post.node.categories.nodes.some(cat => {
                return cat.slug === activeCat
              })
            )
              return null
            postCount = postCount + 1
            return (
              <Article firstarticle={postCount === 1} key={post.node.id}>
                {post.node.acfNewsEvents.needsRefresh ? (
                  <button
                    onClick={() =>
                      window.location.replace(
                        `${process.env.GATSBY_BASE_URL}/news-and-events/${post.node.slug}`
                      )
                    }
                  >
                    <NewArticle
                      post={post}
                      postDate={postDate}
                      featuredImage={featuredImage}
                    />
                  </button>
                ) : (
                  <Link to={`/news-and-events/${post.node.slug}`}>
                    <NewArticle
                      post={post}
                      postDate={postDate}
                      featuredImage={featuredImage}
                    />
                  </Link>
                )}
              </Article>
            )
          })}
        </div>
      </div>

      <div className="wrapper">
        <div className="moreLink">
          <button
            disabled={!postsStore.more}
            onClick={loadMorePostsHandler}
            type="button"
          >
            {postsStore.more
              ? `LOAD MORE ${activeCat === "all" ? "" : activeCat} POSTS`
              : `NO MORE ${activeCat === "all" ? "" : activeCat} POSTS`}
          </button>
        </div>
      </div>
      {postsStore.loading && (
        <LoadingModal>
          <div className="innerLoading">
            <div className="innerLoading__spinner">
              <SpinnerAnimation />
            </div>
            <p>Loading more {activeCat === "all" ? "" : activeCat} posts</p>
          </div>
        </LoadingModal>
      )}

      {postsStore.catChange && (
        <LoadingModal>
          <div className="innerLoading">
            <div className="innerLoading__spinner">
              <SpinnerAnimation />
            </div>
            <p>Loading Category</p>
          </div>
        </LoadingModal>
      )}
    </DisplayNewsEventsSection>
  )
}

const DisplayNewsEventsSection = styled.section`
  .wrapper {
    ${medWrapper};
  }

  .articlesWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
  }

  .moreLink {
    position: relative;
    width: 100%;
    margin: 2.5rem auto;
    padding-top: 5rem;
    border-top: 0.25rem solid ${colors.colorPrimary};
    text-align: center;
    z-index: 100;

    button {
      ${Btn1Green};
    }
  }
`

const CatNav = styled.div`
  width: 100%;
  padding: 2rem 4rem;
  background-color: rgba(77, 88, 113, 0.15);

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
      margin: 0 3rem;

      button {
        ${Nav1Slate}
        padding-top: 0.25rem;
        background-color: transparent;
        border: none;
        text-transform: uppercase;
        border-top: 0.2rem solid transparent;
        border-bottom: 0.2rem solid transparent;

        &:focus {
          box-shadow: none;
        }

        &.activeCat {
          border-top: 0.2rem solid ${colors.colorSecondary};
          border-bottom: 0.2rem solid ${colors.colorSecondary};
        }
      }
    }
  }
`

const Article = styled.div`
  width: 100%;
  margin: 2rem auto;
  position: ${props => (props.firstarticle ? "inital" : "relative")};
  padding-bottom: ${props => (props.firstarticle ? "0rem" : "5rem")};
  border: solid 0.3rem ${colors.colorPrimary};
  transition: all 0.3s ease-out;
  cursor: pointer;

  @media (min-width: 768px) {
    width: ${props =>
      props.firstarticle ? "calc(100% - 18rem)" : "calc(33.333333% - 4rem)"};
    margin: ${props => (props.firstarticle ? "4rem 9rem" : "4rem 2rem")};
  }

  &:hover {
    box-shadow: 3px 7px 9px 0 rgba(77, 88, 113, 0.55);
    cursor: pointer;
  }

  button {
    display: block;
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: 100%;
  }

  article {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  .image {
    position: relative;
    width: 100%;
    height: 30rem;

    @media (min-width: 768px) {
      width: ${props => (props.firstarticle ? "calc(50%)" : "calc(100%)")};
      height: ${props => (props.firstarticle ? "40rem" : "25rem")};
    }

    .imageWrapper {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      z-index: 1;

      div {
        width: 100%;
        height: 100%;
      }
    }
  }

  .content {
    position: ${props => (props.firstarticle ? "relative" : "inital")};
    width: 100%;
    padding: 3rem;
    padding-bottom: 7rem;
    text-align: left;

    @media (min-width: 768px) {
      width: ${props => (props.firstarticle ? "calc(50%)" : "calc(100%)")};
    }
  }

  .contentCats {
    width: 100%;

    p {
      ${B1Slate};
      margin-bottom: 1rem;
      text-transform: uppercase;
    }
  }

  .contentTitle {
    width: 100%;

    h2 {
      ${HIntroGreen};
      text-transform: uppercase;
    }
  }

  .contentExcerpt {
    width: 100%;

    p {
      ${B1Slate};
    }
  }

  .contentDate {
    position: absolute;
    bottom: 3rem;
    left: 3rem;

    p {
      ${B1Slate};
      margin: 0;
      text-transform: uppercase;
    }
  }
`

const LoadingModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(102, 58, 135, 0.7);
  z-index: 999999;

  .innerLoading {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-self: center;
    background-color: ${colors.white};
    width: 40rem;
    margin: 0 auto;
    padding: 5rem 2rem;
    text-align: center;

    p {
      ${B1Slate};
      margin: 0;
    }

    &__spinner {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-self: center;
      width: 100%;
      height: 3.5rem;
      margin: 0 auto;
    }
  }
`

export default DisplayNewsEvents
