import React from "react"
import styled from "styled-components"
import BGImg from "gatsby-background-image"

const HeroImage = ({ data }) => {
  const heroImage = data.image.localFile.childImageSharp.fluid
  return (
    <HeroImageStyled>
      <div className="heroImage">
        <BGImg tag="div" fluid={heroImage} />
      </div>
    </HeroImageStyled>
  )
}

const HeroImageStyled = styled.div`
  position: relative;
  height: 20rem;

  @media (min-width: 768px) {
    height: 40rem;
  }

  @media (min-width: 1025px) {
    height: 55rem;
  }

  .heroImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    div {
      width: 100%;
      height: 100%;
    }
  }
`

export default HeroImage
