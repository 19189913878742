import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { medWrapper } from "../../styles/helpers"
import Wysiwyg from "./Wysiwyg"
const RepeatableCircleContent = ({ data }) => {
  return (
    <RepeatableCircleContentSection>
      <div className="wrapper">
        <div className="blocks">
          {data.blocks.map((block, index) => {
            const imageDisplay = getImage(
              block.image.localFile.childImageSharp.gatsbyImageData
            )
            const imageAlt = block.image.altText
            return (
              <BlockStyled key={index}>
                <div className="image">
                  <GatsbyImage
                    image={imageDisplay}
                    alt={imageAlt}
                    layout="fixed"
                  />
                </div>
                <div className="content">
                  <Wysiwyg data={{ wysiwyg: block.content }} />
                </div>
              </BlockStyled>
            )
          })}
        </div>
      </div>
    </RepeatableCircleContentSection>
  )
}

const RepeatableCircleContentSection = styled.section`
  .wrapper {
    ${medWrapper}
  }
`

const BlockStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 2.5rem;
  padding: 0;

  @media (min-width: 768px) {
    padding: 2rem 4.5rem 0;
  }

  .image {
    width: calc(100% - 10rem);
    margin: 2rem 5rem;

    @media (min-width: 768px) {
      width: calc(15%);
    }
  }

  .content {
    width: 100%;
    margin-top: 2.5rem;
    padding: 0;

    @media (min-width: 768px) {
      width: calc(85%);
      margin-top: 0;
      padding: 0 3rem;
    }
  }
`

export default RepeatableCircleContent
