import React, { useState } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  HIntroGreen,
  H1Blueberry,
  medWrapper,
  colors,
  H3White,
  B1White,
  B1Slate,
  Btn1Green,
  fontSizer,
} from "../../styles/helpers"

const getData = graphql`
  {
    members: allWpBoardOfDirector(sort: { order: ASC, fields: date }) {
      edges {
        node {
          title
          id
          postTypeBoardOfDirectors {
            arbiBio
            arbiExcerpt
            fieldGroupName
            arbiFeaturedImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
          }
        }
      }
    }
  }
`

const DisplayBod = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(null)
  const memData = useStaticQuery(getData)
  const members = memData.members.edges

  return (
    <DisplayBodStyled>
      <div className="wrapper">
        <div className="intro">
          <h2>{data.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: data.intro }} />
        </div>
        <div className="membersWrap">
          {members.map((member, index) => {
            const imageDisplay = getImage(
              member.node.postTypeBoardOfDirectors.arbiFeaturedImage.localFile
                .childImageSharp.gatsbyImageData
            )
            const imageAlt =
              member.node.postTypeBoardOfDirectors.arbiFeaturedImage.altText
            return (
              <>
                <Member key={member.node.id}>
                  <div className="image">
                    <GatsbyImage
                      image={imageDisplay}
                      alt={imageAlt}
                      layout="fixed"
                    />
                  </div>
                  <div className="excerpt">
                    <h3>{member.node.title}</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          member.node.postTypeBoardOfDirectors.arbiExcerpt,
                      }}
                    />
                    <div className="bioBtn">
                      <button onClick={() => setActiveIndex(index)}>
                        Read Bio
                      </button>
                    </div>
                  </div>
                </Member>
                <BioModal activepopup={activeIndex === index}>
                  <div className="innerModal">
                    <div className="innerModal__image">
                      <div className="innerModal__image--wraper">
                        <GatsbyImage
                          image={imageDisplay}
                          alt={imageAlt}
                          layout="fixed"
                        />
                      </div>
                      <div className="innerModal__image--name">
                        <p>{member.node.title}</p>
                      </div>
                    </div>
                    <div className="innerModal__content">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: member.node.postTypeBoardOfDirectors.arbiBio,
                        }}
                      />
                      <div>
                        <button onClick={() => setActiveIndex(null)}>
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className="backgroundOverlay"
                    onClick={() => setActiveIndex(null)}
                  />
                </BioModal>
              </>
            )
          })}
        </div>
      </div>
    </DisplayBodStyled>
  )
}

const DisplayBodStyled = styled.section`
  .wrapper {
    ${medWrapper};
  }

  .intro {
    text-align: center;
    max-width: 90rem;
    margin: 2rem auto;

    h2 {
      ${H1Blueberry};
    }

    p {
      ${HIntroGreen};
    }
  }

  .membersWrap {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
  }
`

const Member = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  background-color: ${colors.colorPrimary};
  width: calc(100%);
  margin: 2rem auto;

  @media (min-width: 768px) {
    width: calc((100% / 3) - 2rem);
    margin: 1rem;
  }

  .image {
    width: calc(100%);
  }

  .excerpt {
    padding: 2rem 4rem;
    background-color: ${colors.colorPrimary};

    h3 {
      ${H3White};
    }

    p {
      ${B1White};
    }
  }

  .bioBtn {
    position: absolute;
    bottom: 2rem;
    left: 4rem;
    width: 100%;
    z-index: 10;

    button {
      ${B1Slate};
      background-color: transparent;
      border: none;
      color: ${colors.colorSecondary};
      text-transform: uppercase;
      cursor: pointer;
    }
  }
`

const BioModal = styled.div`
  display: ${props => (props.activepopup ? "block" : "block")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  overflow: scroll;
  transition: all 0.3s ease;
  opacity: ${props => (props.activepopup ? 1 : 0)};
  z-index: ${props => (props.activepopup ? 999999 : -1)};

  .backgroundOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    backdrop-filter: ${props =>
      props.activepopup ? "blur(10px)" : "blur(0px)"};
    opacity: ${props => (props.activepopup ? 1 : 0)};
    z-index: 1;
  }

  .innerModal {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 110rem;
    transition: all 0.3s ease-out;
    transform: ${props =>
      props.activepopup ? "translate(-50%, -50%)" : "translate(-50%, 200%)"};
    background-color: ${colors.colorPrimary};
    z-index: 10;

    &__image {
      width: calc(100%);
      background-color: ${colors.colorPrimary};

      @media (min-width: 768px) {
        width: calc(33.333333%);
      }

      &--wraper {
        display: none;

        @media (min-width: 768px) {
          display: block;
        }
      }

      &--name {
        padding: 2rem 1rem 1.5rem;

        @media (min-width: 768px) {
          padding: 3rem;
        }

        p {
          ${H3White};
          ${fontSizer(1.8, 2, 76.8, 150, 1.6)}
          margin: 0;
          text-align: center;

          @media (min-width: 768px) {
            text-align: left;
          }
        }
      }
    }

    &__content {
      width: calc(100%);
      padding: 2rem 1rem;
      background-color: ${colors.white};

      @media (min-width: 768px) {
        width: calc(66.666666%);
        padding: 6rem;
      }

      p {
        ${B1Slate};
        ${fontSizer(1.4, 1.8, 76.8, 150, 1.6)};
      }

      button {
        ${Btn1Green};
        margin: 0;
      }
    }
  }
`

export default DisplayBod
