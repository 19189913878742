import React from "react"
import styled from "styled-components"
import {
  colors,
  H1Blueberry,
  H2Teal,
  standardWrapper,
} from "../../styles/helpers"

import Wysiwyg from "./Wysiwyg"

const WysiwygWithQuote = ({ data }) => (
  <WysiwygWithQuoteStyled bcactive={data.bcActive}>
    <div className="wrapper">
      {data.title && (
        <div className="title">
          <h2>{data.title}</h2>
        </div>
      )}
      <div className="qoute">
        <p>{data.quote}</p>
      </div>
      <div className="wysiwyg">
        <Wysiwyg data={{ wysiwyg: data.wysiwyg }} />
      </div>
    </div>
  </WysiwygWithQuoteStyled>
)

const WysiwygWithQuoteStyled = styled.section`
  background-color: ${props =>
    props.bcactive ? colors.colorTertiary : "transparent"};

  .wrapper {
    ${standardWrapper};

    @media (min-width: 768px) {
      padding-top: 6.25rem;
    }
  }

  .wysiwyg {
    width: 100%;
    max-width: 100%;
  }

  .title {
    width: 100%;
    max-width: 90rem;
    text-align: center;

    h2 {
      ${H1Blueberry};
    }
  }

  .qoute {
    width: 100%;
    max-width: 90rem;

    p {
      ${H2Teal};
      margin: 0;
    }
  }
`

export default WysiwygWithQuote
