import { Link } from "gatsby"
import React, { useEffect } from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { colors, H2Teal, standardWrapper } from "../../styles/helpers"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const CalloutButtonsImage = ({ data }) => {
  useEffect(() => {
    const btnOne = document.querySelector(".callout-link-button-1")
    const btnTwo = document.querySelector(".callout-link-button-2")

    gsap.set(btnOne, {
      autoAlpha: 0,
      x: -200,
    })

    gsap.set(btnTwo, {
      autoAlpha: 0,
      x: 200,
    })

    gsap
      .timeline({
        scrollTrigger: {
          trigger: "#callout-link-btn-wrapper",
          markers: false,
          start: "top 45%",
          toggleActions: "play none none none",
        },
      })
      .to([btnOne, btnTwo], {
        autoAlpha: 1,
        x: 0,
        y: 0,
        ease: "power4.out",
        stagger: {
          amount: 0.6,
        },
      })
  }, [])

  return (
    <CalloutButtonsImageSection id="callout-link-btn-wrapper">
      <div className="wrapper">
        {data.callout.map((btn, index) => {
          const imageDisplay = getImage(
            btn.image.localFile.childImageSharp.gatsbyImageData
          )
          const imageAlt = btn.image.altText
          return (
            <LinkButton
              className={`callout-link-button-${index + 1}`}
              color={btn.color}
              key={index}
            >
              {btn.needRefresh ? (
                <button
                  onClick={() =>
                    window.location.replace(
                      `${process.env.GATSBY_BASE_URL}/${btn.slug}`
                    )
                  }
                >
                  <div className="title">
                    <h3>{btn.title}</h3>
                  </div>
                  <div>
                    <GatsbyImage
                      image={imageDisplay}
                      alt={imageAlt}
                      layout="fixed"
                    />
                  </div>
                  <div className="bottomBanner" />
                </button>
              ) : (
                <Link to={`/${btn.slug}`}>
                  <div className="title">
                    <h3>{btn.title}</h3>
                  </div>
                  <div>
                    <GatsbyImage
                      image={imageDisplay}
                      alt={imageAlt}
                      layout="fixed"
                    />
                  </div>
                  <div className="bottomBanner" />
                </Link>
              )}
            </LinkButton>
          )
        })}
      </div>
    </CalloutButtonsImageSection>
  )
}

const CalloutButtonsImageSection = styled.section`
  .wrapper {
    ${standardWrapper};
  }
`

const LinkButton = styled.div`
  width: 100%;
  margin: 1rem auto;
  cursor: pointer;

  @media (min-width: 768px) {
    width: calc(50% - 2rem);
    margin: 2rem 1rem;
  }

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .title {
    text-align: center;
    padding: 5rem;
    background-color: ${props =>
      props.color === "purple"
        ? "rgba(77, 88, 113, 0.25)"
        : "rgba(229, 237, 231, 1)"};

    h3 {
      ${H2Teal};
      margin: 0;
    }
  }

  .bottomBanner {
    height: 4rem;
    margin-top: -1rem;
    background-color: ${props =>
      props.color === "purple" ? colors.colorShad : colors.colorAccent};
  }
`

export default CalloutButtonsImage
