import React, { useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { colors, H3White, medWrapper, Btn1Green } from "../../styles/helpers"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const isBrowser = () => typeof window !== "undefined"

const CalloutButtons = ({ data }) => {
  useEffect(() => {
    gsap.set(`.callout-button-1`, {
      autoAlpha: 0,
      x: -200,
    })

    gsap.set(`.callout-button-2`, {
      autoAlpha: 0,
      x: 200,
    })

    gsap
      .timeline({
        scrollTrigger: {
          trigger: "#callout-buttons",
          markers: false,
          start: "top 35%",
          toggleActions: "play none none none",
        },
      })
      .add("start")
      .to(
        ".callout-button-1",
        {
          autoAlpha: 1,
          x: 0,
          ease: "back.out(1.7)",
        },
        "start"
      )
      .to(
        ".callout-button-2",
        {
          autoAlpha: 1,
          x: 0,
          ease: "back.out(1.7)",
        },
        "start+=0.3"
      )
  }, [])
  return (
    <CalloutButtonsStyled id="callout-buttons">
      <div className="wrapper">
        {data.callout.map((button, index) => {
          return (
            <ButtonStyled className={`callout-button-${index + 1}`} key={index}>
              <div className="innerWarpper">
                <div className="content">
                  <h3>{button.content}</h3>
                </div>
                <div className="link">
                  {button.buttonSlug === "donate" && isBrowser() ? (
                    <button
                      onClick={() =>
                        window.location.replace(
                          `${process.env.GATSBY_BASE_URL}/${button.buttonSlug}`
                        )
                      }
                    >
                      {button.buttonText}
                    </button>
                  ) : (
                    <Link to={`/${button.buttonSlug}`}>
                      {button.buttonText}
                    </Link>
                  )}
                </div>
              </div>
            </ButtonStyled>
          )
        })}
      </div>
    </CalloutButtonsStyled>
  )
}

const CalloutButtonsStyled = styled.section`
  .wrapper {
    ${medWrapper}
  }
`

const ButtonStyled = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  padding: 4.5rem;
  border-radius: 0.6rem;
  background-color: ${colors.colorAccent};

  @media (min-width: 768px) {
    width: calc(50% - 2rem);
    margin: 1rem;
  }

  .innerWarpper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }

  .content {
    h3 {
      ${H3White};
    }
  }

  .link {
    a,
    button {
      ${Btn1Green};
      background-color: transparent;
    }
  }
`

export default CalloutButtons
