import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import { colors, Nav1Slate } from "../../styles/helpers"

import Linkedin from "../Icons/Linkedin"
import Facebook from "../Icons/Facebook"
import Twitter from "../Icons/Twitter"
import Vimeo from "../Icons/Vimeo"
import Instagram from "../Icons/Instagram"

const getData = graphql`
  {
    socialIcons: wp {
      acfOptionsSiteWideSettings {
        acfSiteWideSettings {
          linkedinUrl
          instagramUrl
          youtubeUrl
          twitterUrl
          facebookUrl
          vimeoUrl
        }
      }
    }
  }
`

const SocialMedia = ({ darkMode }) => {
  const data = useStaticQuery(getData)
  return (
    <SocialMediaStyled darkMode={darkMode}>
      <ul className="socialIcons">
        <StyledIcon darkMode={darkMode}>
          <a
            title="Follow us on Linkedin - Link will open in new window"
            target="_blank"
            rel="noreferrer"
            href={
              data.socialIcons.acfOptionsSiteWideSettings.acfSiteWideSettings
                .linkedinUrl
            }
          >
            <i>
              <Linkedin />
              <span className="visuallyhidden">Linkedin</span>
            </i>
          </a>
        </StyledIcon>
        <StyledIcon darkMode={darkMode}>
          <a
            title="Follow us on Facebook - Link will open in new window"
            target="_blank"
            rel="noreferrer"
            href={
              data.socialIcons.acfOptionsSiteWideSettings.acfSiteWideSettings
                .facebookUrl
            }
          >
            <i>
              <Facebook />
              <span className="visuallyhidden">Facebook</span>
            </i>
          </a>
        </StyledIcon>
        <StyledIcon darkMode={darkMode}>
          <a
            title="Follow us on Instagram - Link will open in new window"
            target="_blank"
            rel="noreferrer"
            href={
              data.socialIcons.acfOptionsSiteWideSettings.acfSiteWideSettings
                .instagramUrl
            }
          >
            <i>
              <Instagram />
              <span className="visuallyhidden">Instagram</span>
            </i>
          </a>
        </StyledIcon>
        <StyledIcon darkMode={darkMode}>
          <a
            title="Follow us on Twitter - Link will open in new window"
            target="_blank"
            rel="noreferrer"
            href={
              data.socialIcons.acfOptionsSiteWideSettings.acfSiteWideSettings
                .twitterUrl
            }
          >
            <i>
              <Twitter />
              <span className="visuallyhidden">Twitter</span>
            </i>
          </a>
        </StyledIcon>
        {/* <StyledIcon>
          <a
            title="Follow us on Vimeo - Link will open in new window"
            target="_blank"
            rel="noreferrer"
            href={
              data.socialIcons.acfOptionsSiteWideSettings.acfSiteWideSettings
                .vimeoUrl
            }
          >
            <i>
              <Vimeo />
              <span className="visuallyhidden">Vimeo</span>
            </i>
          </a>
        </StyledIcon> */}
      </ul>
    </SocialMediaStyled>
  )
}

const SocialMediaStyled = styled.div`
  width: 100%;
  margin: -2rem auto 0rem;

  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-self: center;
    justify-content: center;
    width: calc(100%);
  }

  @media (min-width: 1025px) {
    width: calc(100%);
    justify-content: flex-end;
  }

  p {
    ${Nav1Slate};
    margin: 0;
    margin-right: 1rem;
    text-transform: uppercase;
  }

  .socialIcons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-self: center;
    width: 100%;
    padding: 0;
  }
`

const StyledIcon = styled.li`
  display: inline-block;
  margin-right: 1rem;
  margin-left: 1rem;

  &:first-of-type {
    margin-left: 0;
  }

  a {
    display: block;
    position: relative;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;

    @media (min-width: 768px) {
      width: 3rem;
      height: 3rem;
    }
    @media (min-width: 1025px) {
      width: 3rem;
      height: 3rem;
    }

    .visuallyhidden {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }

    svg {
      display: block;
      width: 4rem;
      height: 4rem;
      margin: auto;
      transition: all 0.3s ease-out;
      fill: ${colors.white};
      ${props => (props.darkMode ? "fill: #663a87;" : null)}

      @media (min-width: 768px) {
        width: 3rem;
        height: 3rem;
      }
      @media (min-width: 1025px) {
        width: 3rem;
        height: 3rem;
      }

      &:hover {
        fill: ${colors.colorTertiary};
        ${props => (props.darkMode ? "fill: #74bf44;" : null)}
      }
    }
  }
`

export default SocialMedia
