import React, { useEffect } from "react"
import styled from "styled-components"
import { colors, H1White, medWrapper } from "../../styles/helpers"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const TitleSection = ({ data }) => {
  const letters = data.title.split("").map(letter => {
    return { letter }
  })

  useEffect(() => {
    const titleLetters = document.querySelectorAll(".title h1 span")

    gsap.fromTo(
      titleLetters,
      {
        autoAlpha: 0,
        y: 50,
      },
      {
        autoAlpha: 1,
        y: 0,
        ease: "power4.out",
        stagger: {
          amount: 0.5,
        },
      }
    )
  }, [])

  return (
    <TitleSectionStyled>
      <div className="wrapper">
        <div className="title">
          <h1>
            {letters.map((char, index) => {
              if (char.letter === " ") return " "
              return <span key={index}>{char.letter}</span>
            })}
          </h1>
        </div>
      </div>
    </TitleSectionStyled>
  )
}

const TitleSectionStyled = styled.section`
  background-color: ${colors.colorPrimary};

  .wrapper {
    ${medWrapper}
  }

  .title {
    width: 100%;
    padding-top: 6.5rem;
    text-align: center;

    h1 {
      ${H1White};
      min-height: 5rem;
      margin-top: 0;
      margin-bottom: 0;
      font-weight: normal;

      span {
        display: inline-block;
      }
    }
  }
`

export default TitleSection
