import React from "react"
import styled from "styled-components"
import {
  H2Blueberry,
  B1Slate,
  standardWrapper,
  Btn1Green,
} from "../../styles/helpers"

const MailChimpButton = () => {
  return (
    <MailChimpButtonSection>
      <div className="wrapper">
        <h2>Join the ARBI Community!</h2>
        <p>
          Subscribe to our mailing list and get the latest ARBI news delivered
          right to your inbox.
        </p>

        <a
          target="_blank"
          rel="noreferrer"
          href="https://arbi.us13.list-manage.com/subscribe?u=3fb293250f141a7c2aa6f306a&id=20b361b910"
        >
          Subscribe Here
        </a>
      </div>
    </MailChimpButtonSection>
  )
}

const MailChimpButtonSection = styled.section`
  padding: 5rem 0;
  .wrapper {
    ${standardWrapper};
  }

  h2 {
    ${H2Blueberry};
    width: 100%;
    text-align: center;
  }

  p {
    ${B1Slate};
    width: 100%;
    text-align: center;
  }

  a {
    ${Btn1Green};
  }
`

export default MailChimpButton
