import React, { useState } from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  B1Slate,
  Btn1Green,
  colors,
  H2Teal,
  Nav1Slate,
  standardWrapper,
} from "../../styles/helpers"

const Directory = ({ data }) => {
  const [activeCat, setActiveCat] = useState("all")
  return (
    <DirectorySection>
      <div className="wrapper">
        <div className="title">
          <h2>{data.title}</h2>
        </div>
      </div>
      <div className="cat-nav">
        <ul>
          <li>
            <button
              className={activeCat === "all" && "activeCat"}
              onClick={() => {
                setActiveCat("all")
              }}
            >
              ALL
            </button>
          </li>
          <li>
            <button
              className={activeCat === "family" && "activeCat"}
              onClick={() => {
                setActiveCat("family")
              }}
            >
              FAMILY & CAREGIVER SUPPORT
            </button>
          </li>
          <li>
            <button
              className={activeCat === "financial" && "activeCat"}
              onClick={() => {
                setActiveCat("financial")
              }}
            >
              FINANCIAL & TAX SUPPORT{" "}
            </button>
          </li>
          <li>
            <button
              className={activeCat === "general" && "activeCat"}
              onClick={() => {
                setActiveCat("general")
              }}
            >
              GENERAL INFO
            </button>
          </li>
          <li>
            <button
              className={activeCat === "housing" && "activeCat"}
              onClick={() => {
                setActiveCat("housing")
              }}
            >
              HOUSING & TRANSPORTATION
            </button>
          </li>
          <li>
            <button
              className={activeCat === "survivor" && "activeCat"}
              onClick={() => {
                setActiveCat("survivor")
              }}
            >
              SURVIVOR SUPPORT
            </button>
          </li>
        </ul>
      </div>
      <div className="wrapper">
        <div className="resources">
          {data.resourceItems.map((item, index) => {
            const imageDisplay = getImage(
              item.logo.localFile.childImageSharp.gatsbyImageData
            )
            const imageAlt = item.logo.altText

            if (activeCat !== "all" && activeCat !== item.category) return null

            return (
              <ResourceItem key={index}>
                <div className="item-content">
                  <div className="item-content__title">
                    <h3>{item.title}</h3>
                  </div>
                  <div
                    className="item-content__content"
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                  <div className="item-link">
                    <a target="_blank" rel="noreferrer" href={item.websiteUrl}>
                      Website Link
                    </a>
                  </div>
                </div>
                <div className="item-image">
                  <GatsbyImage
                    image={imageDisplay}
                    alt={imageAlt}
                    layout="fixed"
                  />
                </div>
              </ResourceItem>
            )
          })}
        </div>
      </div>
    </DirectorySection>
  )
}

const DirectorySection = styled.section`
  .wrapper {
    ${standardWrapper};
  }

  .title {
    width: 100%;

    h2 {
      ${H2Teal};
      text-align: center;
    }
  }

  .cat-nav {
    width: 100%;
    padding: 2rem;
    margin-bottom: 5rem;
    background-color: rgba(77, 88, 113, 0.15);

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      li {
        margin: 1rem 2rem;

        button {
          ${Nav1Slate};
          display: inline-block;
          padding-top: 0.25rem;
          background-color: transparent;
          border: none;
          border-top: 0.2rem solid transparent;
          border-bottom: 0.2rem solid transparent;

          &:focus {
            box-shadow: none;
          }
        }

        .activeCat {
          border-top: 0.2rem solid ${colors.colorSecondary};
          border-bottom: 0.2rem solid ${colors.colorSecondary};
        }
      }
    }
  }

  .resources {
    width: 100%;
  }
`

const ResourceItem = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-bottom: 7.5rem;
  padding-bottom: 2.5rem;
  border-bottom: solid 0.25rem ${colors.colorSecondary};

  @media (min-width: 768px) {
    margin-bottom: 2.5rem;
  }

  .item-content {
    width: calc(100%);

    @media (min-width: 768px) {
      width: calc(70% - 5rem);
      margin-right: 5rem;
    }

    &__title {
      h3 {
        ${B1Slate};
        text-transform: uppercase;
      }
    }

    &__content {
      p {
        ${B1Slate}
      }
    }
  }

  .item-image {
    width: calc(100% - 15rem);
    margin: 5rem 15rem 0rem 0rem;

    @media (min-width: 768px) {
      width: calc(30%);
      margin: 0;
    }
  }

  .item-link {
    a {
      ${Btn1Green};
      background-color: transparent;
    }
  }
`

export default Directory
