import React from "react"
import styled from "styled-components"
import {
  H1Blueberry,
  H2Base,
  H2Blueberry,
  H2Teal,
  H3Blueberry,
  H4Blueberry,
  medWrapper,
} from "../../styles/helpers"
import Wysiwyg from "./Wysiwyg"

const CardsRow = ({ data }) => {
  console.log("data", data)
  return (
    <StyledSection>
      <div className="wrapper">
        <div className="card-main-title">
          <h2>{data.sectionTitle}</h2>
        </div>
        <div className="cards">
          {data.cardsSection.map((sec, index) => {
            return (
              <CardSection key={index}>
                <div className="cards-section-title">
                  <h3>{sec.cardSectionTitle}</h3>
                  <p>{sec.cardSectionSubTitle}</p>
                </div>
                <div className="cards-section-wrapper">
                  {sec.cards.map((card, index) => {
                    return (
                      <Card key={index}>
                        <div className="single-card-title">
                          <h3>{card.cardTitle}</h3>
                        </div>
                        <Wysiwyg data={{ wysiwyg: card.cardContent }} />
                      </Card>
                    )
                  })}
                </div>
              </CardSection>
            )
          })}
        </div>
      </div>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  .wrapper {
    ${medWrapper};
  }

  .card-main-title {
    width: 100%;

    @media (min-width: 768px) {
      padding-left: 2rem;
    }

    h2 {
      ${H2Teal};
    }
  }

  .cards {
    width: 100%;
  }
`

const CardSection = styled.div`
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;

  .cards-section-title {
    width: 100%;

    @media (min-width: 768px) {
      padding-left: 2rem;
    }

    h3 {
      ${H1Blueberry};
      margin-bottom: 0;
    }

    p {
      ${H4Blueberry};
    }
  }

  .cards-section-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
`

const Card = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  padding: 2rem;
  box-shadow: 0.25rem 0.2rem 1rem 0rem rgba(0, 0, 0, 0.2);

  @media (min-width: 768px) {
    width: calc((100% / 2) - 4rem);
    margin: 2rem;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0.25rem 0.2rem 1rem 0rem rgba(0, 0, 0, 0.2);
  }

  @media (min-width: 1025px) {
    width: calc((100% / 3) - 4rem);
    margin: 2rem;
    padding: 2rem;
  }

  .single-card-title {
    h3 {
      ${H2Blueberry};
      margin-bottom: 0rem;
    }
  }
`

export default CardsRow
