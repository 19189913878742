import React from "react"
import styled from "styled-components"
import {
  colors,
  medWrapper,
  fonts,
  H1Blueberry,
  H2Blueberry,
  H3Blueberry,
  H4Blueberry,
  B1Slate,
  fontSizer,
  H2Teal,
} from "../../styles/helpers"
import { Link } from "gatsby"

const SideBySideItemList = ({ data }) => {
  return (
    <StyledSection>
      <div className="wrapper">
        <div className="main-title">
          <h2>{data.sideBySideItemsListTitle}</h2>
        </div>
        {data.listItemsItems.map((item, index) => {
          return (
            <ListItem key={index}>
              <div className="title">
                {item.titleLinkRequired === "internal" ? (
                  <h2>
                    <Link to={`${item.internalLink.uri}`}>{item.title}</Link>
                  </h2>
                ) : item.titleLinkRequired === "external" ? (
                  <h2>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${item.externalLink}`}
                    >
                      {item.title}
                    </a>
                  </h2>
                ) : item.titleLinkRequired === "no" ? (
                  <h2>{item.title}</h2>
                ) : (
                  <h2>{item.title}</h2>
                )}
              </div>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: item.content }}
              />
            </ListItem>
          )
        })}
      </div>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  .wrapper {
    ${medWrapper};
  }

  .main-title {
    width: 100%;
    padding: 0 4rem;

    h2 {
      ${H2Teal};
    }
  }
`

const ListItem = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: calc(50%);
    padding: 1rem 4rem;
  }

  .title {
    width: 100%;

    h2 {
      ${H3Blueberry};
      margin-bottom: 1rem;

      a {
        ${H3Blueberry};

        &:hover {
          color: ${colors.colorSecondary};
        }
      }
    }
  }

  .content {
    width: 100%;
    max-width: 90rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto;

    &::after {
      display: table;
      clear: both;
      content: "";
    }

    .post-edit-link {
      font-size: 1.6rem;
    }

    blockquote {
      display: block;
      width: 95%;
      margin: 1.5rem auto 2rem;
      padding: 0 2.25rem;
      border-right: 5px solid ${colors.grey};
      border-left: 5px solid ${colors.grey};
      font-size: 1.6rem;
      font-style: $italic;

      @media (min-width: 768px) {
        width: 80%;
        margin: 5em auto;
        padding: 0 2rem;
      }

      p {
        margin-bottom: 0;

        &::before,
        &::after {
          font-family: ${fonts.fontAwesome};
          color: rgba($color-secondary, 1);
        }

        &::before {
          padding-right: 0.25em;
          content: "\f10d";
        }

        &::after {
          padding-left: 0.25em;
          content: "\f10e";
        }
      }
    }

    hr {
      display: block;
      height: 0.25em;
      background-color: ${colors.colorSecondary};
    }

    ul {
      margin-bottom: 2.5rem;

      li {
        ${B1Slate};
        position: relative;
        margin-bottom: 0.75em;
        padding-left: 0.75em;
        font-size: 1.6rem;

        &::before {
          font-family: ${fonts.fontAwesome};
          position: absolute;
          top: 0.1em;
          left: 0;
          padding-right: 0.5em;
          color: rgba($grey, 0.75);
          content: "\f0da";
        }
      }
    }

    ol {
      margin-bottom: 2.5rem;
      margin-left: 1.75rem;
      font-size: 1.6rem;

      li {
        ${B1Slate};
        position: relative;
        margin-bottom: 0.75em;
        font-size: 1.6rem;
        margin-bottom: 0.25rem;
        list-style-position: outside;
        list-style-type: decimal;
      }
    }

    strong {
      font-weight: bold;
    }

    em {
      font-style: $italic;
    }

    h1 {
      ${H1Blueberry};
      ${props =>
        props.theme === "light" ? "color: rgba(245,245,245, 1)" : null};
    }

    h2 {
      ${H2Blueberry};
      ${props =>
        props.theme === "light" ? "color: rgba(245,245,245, 1)" : null};
    }

    h3 {
      ${H3Blueberry}
      ${props =>
        props.theme === "light" ? "color: rgba(245,245,245, 1)" : null};
    }

    h4 {
      ${H4Blueberry}
      ${props =>
        props.theme === "light" ? "color: rgba(245,245,245, 1)" : null};
    }

    h5 {
      ${H4Blueberry}
      ${fontSizer(2, 2.4, 76.8, 150, 2)};
      color: #78be21;
    }

    h6 {
      ${H4Blueberry}
      ${fontSizer(1.8, 2, 76.8, 150, 2)};
      color: #00635d;
    }

    p {
      ${B1Slate};
      ${props =>
        props.theme === "light" ? "color: rgba(245,245,245, 1)" : null};

      a {
        transition: all 0.3s;
        color: ${colors.colorPrimary};
        ${props =>
          props.theme === "light" ? "color: rgba(245,245,245, 1)" : null};
        font-weight: bold;
        font-size: 1em;

        &:hover {
          color: ${colors.colorSecondary};
          ${props =>
            props.theme === "light" ? "color: rgba(120,119,124, 1)" : null};
        }
      }
    }

    del {
      color: ${colors.colorSecondary};
    }

    /* WordPress Core */
    .alignnone {
      margin: 5px 20px 20px 0;
    }

    .aligncenter,
    div.aligncenter {
      display: block;
      margin: 2rem auto;
    }

    .alignright {
      float: right;
      margin: 5px 0 20px 20px;
    }

    .alignleft {
      float: left;
      margin: 5px 20px 20px 0;
    }

    a img.alignright {
      float: right;
      margin: 5px 0 20px 20px;
    }

    a img.alignnone {
      margin: 5px 20px 20px 0;
    }

    a img.alignleft {
      float: left;
      margin: 5px 20px 20px 0;
    }

    a img.aligncenter {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .wp-caption {
      background: transparent;
      border: 1px solid transparent;
      max-width: 96%; /* Image does not overflow the content area */
      padding: 5px 3px 10px;
      text-align: center;
    }

    .wp-caption.alignnone {
      margin: 5px 20px 20px 0;
    }

    .wp-caption.alignleft {
      margin: 5px 20px 20px 0;
    }

    .wp-caption.alignright {
      margin: 5px 0 20px 20px;
    }

    .wp-caption img {
      border: 0 none;
      height: auto;
      margin: 0;
      max-width: 98.5%;
      padding: 0;
      width: auto;
    }

    .wp-caption p.wp-caption-text {
      font-size: 1.1rem;
      line-height: 17px;
      margin: 0;
      padding: 0 4px 5px;
    }

    /* Text meant only for screen readers. */
    .screen-reader-text {
      clip: rect(1px, 1px, 1px, 1px);
      position: absolute !important;
      height: 1px;
      width: 1px;
      overflow: hidden;
    }

    .screen-reader-text:focus {
      background-color: #f1f1f1;
      border-radius: 3px;
      box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
      clip: auto !important;
      color: #21759b;
      display: block;
      font-size: 14px;
      font-size: 0.875rem;
      font-weight: bold;
      height: auto;
      left: 5px;
      line-height: normal;
      padding: 15px 23px 14px;
      text-decoration: none;
      top: 5px;
      width: auto;
      z-index: 100000; /* Above WP toolbar. */
    }

    img {
      width: auto;
    }

    .wp-video {
      width: 100% !important;
      max-width: 100% !important;
    }

    .wp-video-shortcode {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
`

export default SideBySideItemList
