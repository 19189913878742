import React from "react"
import styled from "styled-components"
import {
  B1Slate,
  Btn1Green,
  H2Blueberry,
  medWrapper,
} from "../../styles/helpers"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const ThreeBlocksImages = ({ data }) => {
  return (
    <StyledSection>
      <div className="wrapper">
        {data.threeBlocksImagesBlocks.map((block, index) => {
          const imageDisplay = getImage(
            block.image.localFile.childImageSharp.gatsbyImageData
          )
          const imageAlt = block.image.altText
          return (
            <Block key={index}>
              <div className="image">
                <GatsbyImage
                  image={imageDisplay}
                  alt={imageAlt}
                  layout="fixed"
                />
              </div>
              <div className="main-content">
                <div className="title">
                  <h2>{block.title}</h2>
                </div>
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: block.content }}
                />
                <div className="btn-wrap">
                  {block.linkType === "external" ? (
                    <a target="_blank" rel="noreferrer" href={block.exteral}>
                      {block.buttonText}
                    </a>
                  ) : block.linkType === "download" ? (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={block.download.localFile.publicURL}
                    >
                      {block.buttonText}
                    </a>
                  ) : block.linkType === "internal" ? (
                    <Link to={block.wpInternal.uri}>{block.buttonText}</Link>
                  ) : block.linkType === "email" ? (
                    <a href={`mailto:${block.email}`}>{block.buttonText}</a>
                  ) : null}
                </div>
              </div>
            </Block>
          )
        })}
      </div>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  padding-top: 5rem;

  .wrapper {
    ${medWrapper};
  }
`

const Block = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  margin: 2rem auto;

  @media (min-width: 768px) {
    width: calc(33.33333333% - 2rem);
    margin: 1rem;
  }

  .main-content {
    padding: 1.5rem;

    .title {
      width: 100%;

      h2 {
        ${H2Blueberry};
      }
    }

    .content {
      width: 100%;

      p {
        ${B1Slate};
      }
    }

    .btn-wrap {
      width: 100%;

      a {
        ${Btn1Green};
      }
    }
  }
`

export default ThreeBlocksImages
