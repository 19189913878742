import React, { useEffect } from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { B1Slate, colors, H3White, medWrapper } from "../../styles/helpers"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const ThreeColumns = ({ data }) => {
  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: "#three-col-section",
          markers: false,
          start: "top 35%",
          toggleActions: "play none none none",
        },
      })
      .fromTo(
        ".col-block",
        {
          autoAlpha: 0,
          y: 150,
          duration: 2,
        },
        {
          autoAlpha: 1,
          y: 0,
          duration: 2,
          stagger: {
            each: 0.15,
            from: "center",
          },
        }
      )
  }, [])

  return (
    <ThreeColumnsStyled id="three-col-section">
      <div className="wrapper">
        {data.columns.map((column, index) => {
          const imageDisplay = getImage(
            column.icon.localFile.childImageSharp.gatsbyImageData
          )
          const imageAlt = column.icon.altText
          return (
            <Column className="col-block" key={index}>
              <Link to={`/${column.buttonSlug}`}>
                <div className="icon">
                  <div className="icon__image">
                    <GatsbyImage
                      image={imageDisplay}
                      alt={imageAlt}
                      layout="fixed"
                    />
                  </div>
                </div>
                <div className="title">
                  <h2>{column.title}</h2>
                </div>
                <div className="content">
                  <div dangerouslySetInnerHTML={{ __html: column.content }} />
                  <div className="link">
                    <p>
                      <span>&#62;</span> {column.buttonText}
                    </p>
                  </div>
                </div>
              </Link>
            </Column>
          )
        })}
      </div>
    </ThreeColumnsStyled>
  )
}

const ThreeColumnsStyled = styled.section`
  .wrapper {
    ${medWrapper};
    align-items: stretch;
  }
`

const Column = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: calc(33.333333% - 2rem);
    margin: 1rem;
  }

  .icon {
    width: 100%;

    &__image {
      width: 100%;
      max-width: 11rem;
      margin: 2rem auto;
    }
  }

  .title {
    display: flex;
    justify-content: center;
    width: 100%;

    h2 {
      ${H3White};
      padding: 0.5rem 2.5rem;
      background-color: ${colors.colorAccent};
      transition: all 0.3s ease-out;
      text-align: center;
    }
  }

  .content {
    margin-top: -2.5rem;
    padding: 7rem 4.5rem 1.5rem;
    transition: all 0.3s ease-out;
    background-color: ${colors.colorTertiary};

    p {
      ${B1Slate};
    }

    .link {
      width: 100%;
      text-align: right;

      p {
        ${B1Slate};
        margin-bottom: 0;
        text-transform: uppercase;
      }
    }
  }

  &:hover {
    cursor: pointer;

    .title {
      h2 {
        background-color: ${colors.colorSecondary};
      }
    }
    .content {
      background-color: rgba(116, 191, 68, 0.25);
    }
  }
`

export default ThreeColumns
