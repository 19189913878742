import React from "react"
import styled from "styled-components"
import { standardWrapper, colors, B1Slate, H2Teal } from "../../styles/helpers"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const SimpleContentImage = ({ data }) => {
  const imageDisplay = getImage(
    data.image.localFile.childImageSharp.gatsbyImageData
  )
  const imageAlt = data.image.altText
  return (
    <SimpleContentImageSection bcactive={data.bcActive}>
      <div className="wrapper">
        <div className="content">
          <div className="title">
            <h2>{data.title}</h2>
          </div>
          <div dangerouslySetInnerHTML={{ __html: data.content }} />
        </div>
        <div className="image">
          <GatsbyImage image={imageDisplay} alt={imageAlt} layout="fixed" />
        </div>
      </div>
    </SimpleContentImageSection>
  )
}

const SimpleContentImageSection = styled.section`
  background-color: ${props =>
    props.bcactive ? colors.colorTertiary : "transparent"};

  .wrapper {
    ${standardWrapper};
    align-items: center;
    padding: 10rem 2rem;
  }

  .content {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(60% - 5rem);
      margin-right: 5rem;
    }

    .title {
      width: 100%;
      max-width: 33rem;

      h2 {
        ${H2Teal};
      }
    }

    p {
      ${B1Slate};
    }
  }

  .image {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(40% - 5rem);
      margin-left: 5rem;
    }
  }
`

export default SimpleContentImage
