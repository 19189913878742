import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import Wysiwyg from "./Wysiwyg"
import { H2Teal, standardWrapper } from "../../styles/helpers"

const getData = graphql`
  {
    careers: allWpCareer {
      edges {
        node {
          title
          Careers {
            jobDescription
          }
        }
      }
    }
  }
`

const DisplayCareers = ({ data }) => {
  const careData = useStaticQuery(getData)
  const careers = careData.careers.edges
  if (!data.display) return null

  return (
    <DisplayCareersSection>
      <div className="wrapper">
        {careers.map((career, index) => {
          return (
            <CareerStyled key={index}>
              <div className="title">
                <h2>{career.node.title}</h2>
              </div>
              <div>
                <Wysiwyg
                  data={{ wysiwyg: career.node.Careers.jobDescription }}
                />
              </div>
            </CareerStyled>
          )
        })}
      </div>
    </DisplayCareersSection>
  )
}

const DisplayCareersSection = styled.section`
  .wrapper {
    ${standardWrapper};
    max-width: 90rem;

    @media (min-width: 1025px) {
      padding: 0;
    }
  }
`

const CareerStyled = styled.div`
  .title {
    h2 {
      ${H2Teal};
    }
  }
`

export default DisplayCareers
