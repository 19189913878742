import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { H1Blueberry, standardWrapper } from "../../styles/helpers"

const Infographic = ({ data }) => {
  const desktopDisplay = getImage(
    data.infographicDesktop.localFile.childImageSharp.gatsbyImageData
  )
  const desktopAlt = data.infographicDesktop.altText

  const mobileDisplay = getImage(
    data.infographicMobile.localFile.childImageSharp.gatsbyImageData
  )
  const mobileAlt = data.infographicMobile.altText
  return (
    <InfographicSection>
      <div className="wrapper">
        <div className="title">
          <h2>{data.title}</h2>
        </div>
        <div className="infographic">
          <div className="infographic__desktop">
            <GatsbyImage
              image={desktopDisplay}
              alt={desktopAlt}
              layout="fixed"
            />
          </div>
          <div className="infographic__mobile">
            <GatsbyImage image={mobileDisplay} alt={mobileAlt} layout="fixed" />
          </div>
        </div>
      </div>
    </InfographicSection>
  )
}

const InfographicSection = styled.section`
  .wrapper {
    ${standardWrapper};
  }

  .title {
    width: 100%;
    text-align: center;

    h2 {
      ${H1Blueberry};
    }
  }

  .infographic {
    width: 100%;

    &__desktop {
      display: none;
      width: 100%;
      margin: 2.5rem auto;

      @media (min-width: 768px) {
        display: block;
      }
    }

    &__mobile {
      display: block;
      width: 100%;

      @media (min-width: 768px) {
        display: none;
      }
    }
  }
`

export default Infographic
