import React, { useState } from "react"
import styled from "styled-components"
import { B1Slate, colors, H2Teal, medWrapper } from "../../styles/helpers"

const ListWithContent = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const handleChangeActivePoint = event => {
    setActiveIndex(parseInt(event.target.dataset.index))
  }

  return (
    <ListWithContentSection>
      <div className="wrapper">
        <div className="title">
          <h2>{data.title}</h2>
        </div>
        <div className="listWrap">
          <div className={`listWrap__titles`}>
            {data.listPoints.map((title, index) => {
              return (
                <div
                  key={index}
                  className={`listWrap__titles--item${
                    activeIndex === index ? " active" : ""
                  }`}
                >
                  <p
                    data-index={index}
                    onClick={event => handleChangeActivePoint(event)}
                  >
                    {title.title}
                  </p>
                </div>
              )
            })}
          </div>

          <div className="listWrap__content">
            {data.listPoints.map((content, index) => {
              return (
                <div
                  key={index}
                  className={`listWrap__content--item${
                    activeIndex === index ? " active" : ""
                  }`}
                >
                  <div dangerouslySetInnerHTML={{ __html: content.content }} />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </ListWithContentSection>
  )
}

const ListWithContentSection = styled.section`
  .wrapper {
    ${medWrapper};
  }

  .title {
    width: 100%;
    margin-bottom: 4rem;
    padding-left: 5rem;
    border-bottom: 0.25rem solid ${colors.colorAccent};

    h2 {
      ${H2Teal};
    }
  }

  .listWrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    &__titles {
      width: calc(100%);

      @media (min-width: 768px) {
        width: calc(33.333333%);
      }

      &--item {
        width: 100%;

        &.active {
          background-color: rgba(116, 191, 68, 0.15);
        }

        p {
          ${H2Teal};
          margin: 0;
          padding: 1rem 0;
          padding-left: 5rem;
          text-transform: uppercase;
        }
      }
    }

    &__content {
      width: calc(100%);
      margin-top: 5rem;
      padding: 2rem 2rem;
      background-color: rgba(116, 191, 68, 0.15);

      @media (min-width: 768px) {
        width: calc(66.666666% - 3rem);
        margin-top: 0;
        margin-left: 3rem;
        padding: 4rem 6rem;
      }

      &--item {
        display: none;

        &.active {
          display: block;
        }

        p {
          ${B1Slate};

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`

export default ListWithContent
