import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  colors,
  H1Blueberry,
  medWrapper,
  H2Teal,
  Btn1Green,
} from "../../styles/helpers"
import Wysiwyg from "./Wysiwyg"
import { Link } from "gatsby"

const ContentByStats = ({ data }) => {
  const imageDisplay = getImage(
    data.image.localFile.childImageSharp.gatsbyImageData
  )
  const imageAlt = data.image.altText
  return (
    <ContentByStatsSection>
      <div className="wrapper">
        <div className="content">
          <div className="content__title">
            <h2>{data.title}</h2>
          </div>
          <div className="content__image">
            <GatsbyImage image={imageDisplay} alt={imageAlt} layout="fixed" />
          </div>
          <div className="content__paragraph">
            <Wysiwyg data={{ wysiwyg: data.content }} />
          </div>
          <div className="content__button">
            <Link to={`/${data.buttonSlug}`}>{data.buttonText}</Link>
          </div>
        </div>
        <div className="stats">
          <div className="stats__title">
            <h2>{data.statTitle}</h2>
          </div>
          <div className="stats__content">
            <ul>
              {data.stats.map((stat, index) => {
                return <li key={index}>{stat.stat}</li>
              })}
            </ul>
          </div>
        </div>
      </div>
    </ContentByStatsSection>
  )
}

const ContentByStatsSection = styled.section`
  .wrapper {
    ${medWrapper}
  }

  .content {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(50% - 2rem);
      margin: 1rem;
    }

    &__title {
      padding-right: 2rem;

      h2 {
        ${H1Blueberry};
      }
    }

    &__button {
      a {
        ${Btn1Green};
        background-color: transparent;
      }
    }
  }

  .stats {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(50% - 2rem);
      margin: 1rem;
    }

    &__title {
      padding-left: 4rem;

      h2 {
        ${H1Blueberry};
      }
    }

    &__content {
      padding: 6.5rem 4rem;
      background-color: rgba(77, 88, 113, 0.11);

      ul {
        li {
          ${H2Teal};
          padding: 3.5rem 0;
          border-bottom: 0.1rem solid ${colors.colorAccent};
        }

        li:first-of-type {
          padding-top: 0;
        }
      }
    }
  }
`

export default ContentByStats
