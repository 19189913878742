import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Wysiwyg from "./Wysiwyg"
import { Btn1Green, H2Teal, medWrapper } from "../../styles/helpers"
import { Link } from "gatsby"

const ImageByWysiwyg = ({ data }) => {
  const imageTopDisplay = getImage(
    data.topImage.localFile.childImageSharp.gatsbyImageData
  )
  const imageTopAlt = data.topImage.altText

  let imageBotDisplay
  let imageBotAlt

  if (data.bottomRequired) {
    imageBotDisplay = getImage(
      data?.bottomImage?.localFile?.childImageSharp?.gatsbyImageData
    )
    imageBotAlt = data?.bottomImage?.altText
  }

  return (
    <ImageByWysiwygSection>
      <div className="wrapper">
        <div className="images">
          <GatsbyImage
            className="top-image"
            image={imageTopDisplay}
            alt={imageTopAlt}
            layout="fixed"
          />
          {data.bottomRequired && data.bottomImage && (
            <GatsbyImage
              className="bottom-image"
              image={imageBotDisplay}
              alt={imageBotAlt}
              layout="fixed"
            />
          )}
        </div>

        <div className="content">
          <div className="content__title">
            <h2>{data.title}</h2>
          </div>
          <Wysiwyg data={{ wysiwyg: data.wysiwyg }} />
          {data.buttonRequired && data.buttonType === "internal" ? (
            <div className="content__link">
              <Link to={`/${data.buttonSlug}`}>{data.buttonText}</Link>
            </div>
          ) : data.buttonRequired && data.buttonType === "external" ? (
            <div className="content__link">
              <a target="_blank" rel="noreferrer" href={`${data.buttonUrl}`}>
                {data.buttonText}
              </a>
            </div>
          ) : null}
        </div>
      </div>
    </ImageByWysiwygSection>
  )
}

const ImageByWysiwygSection = styled.section`
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;

  .wrapper {
    ${medWrapper};
  }

  .images {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(30%);
    }

    .top-image {
      margin-bottom: 2rem;

      @media (min-width: 768px) {
        margin-bottom: 1rem;
      }
    }

    .bottom-image {
      display: none;

      @media (min-width: 768px) {
        display: block;
      }
    }
  }

  .content {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(70% - 4rem);
      margin-left: 4rem;
    }

    &__title {
      h2 {
        ${H2Teal};
        margin-top: 0;
      }
    }

    &__link {
      a {
        ${Btn1Green};
        background-color: transparent;
      }
    }
  }
`

export default ImageByWysiwyg
