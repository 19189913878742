import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  HIntroWhite,
  colors,
  H2White,
  standardWrapper,
} from "../../styles/helpers"

const BannerColorBg = ({ data }) => {
  const imageDisplay = getImage(
    data.image.localFile.childImageSharp.gatsbyImageData
  )
  const imageAlt = data.image.altText
  return (
    <BannerColorBgStyled>
      <div className="wrapper">
        <div className="banner">
          <div className="banner__title">
            <h2>{data.title}</h2>
            <div className="icon">
              <div className="icon__image">
                <GatsbyImage
                  image={imageDisplay}
                  alt={imageAlt}
                  layout="fixed"
                />
              </div>
            </div>
          </div>
          <div
            className="banner__content"
            dangerouslySetInnerHTML={{ __html: data.content }}
          />
        </div>
      </div>
    </BannerColorBgStyled>
  )
}

const BannerColorBgStyled = styled.section`
  background-color: ${colors.colorAccent};

  .wrapper {
    ${standardWrapper};
  }

  .banner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding: 4rem 6rem;
    background-color: ${colors.colorAccent};

    &__title {
      width: 100%;

      @media (min-width: 768px) {
        width: calc(25%);
      }

      h2 {
        ${H2White};
        margin-bottom: 5rem;
      }

      .icon {
        width: 100%;

        &__image {
          width: 100%;
          max-width: 22rem;
          margin: 2rem auto;
        }
      }
    }

    &__content {
      width: 100%;

      @media (min-width: 768px) {
        width: calc(75% - 6rem);
        margin-left: 6rem;
      }

      p {
        ${HIntroWhite};

        &:last-of-type {
          margin: 0;
        }
      }
    }
  }
`

export default BannerColorBg
