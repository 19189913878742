import React, { useState } from "react"
import styled from "styled-components"
import { Btn1Green, standardWrapper } from "../../styles/helpers"

import DonateForm from "../Modals/DonateForm"

const EmbedCode = ({ data }) => {
  const [modalActive, setModalActive] = useState(false)
  return (
    <EmbedCodeStyled>
      <div className="wrapper">
        <div className="donate-button">
          <button
            onClick={() => {
              setModalActive(!modalActive)
            }}
          >
            Donate Form
          </button>
        </div>
        {modalActive && <DonateForm />}
      </div>
    </EmbedCodeStyled>
  )
}

const EmbedCodeStyled = styled.div`
  width: 100%;

  .wrapper {
    ${standardWrapper};
  }

  .donate-button {
    width: 100%;
    text-align: center;

    button {
      ${Btn1Green};
      background-color: transparent;
    }
  }
`

export default EmbedCode
