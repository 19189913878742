import React from "react"
import styled from "styled-components"
import { HIntroGreen, standardWrapper } from "../../styles/helpers"

const Quote = ({ data }) => {
  return (
    <QuoteSection>
      <div className="wrapper">
        <div className="quote">
          <div dangerouslySetInnerHTML={{ __html: data.quoteContent }} />
          <div>
            <p>
              <span>&#8211;</span> {data.quoteAuthor}
            </p>
          </div>
        </div>
      </div>
    </QuoteSection>
  )
}

const QuoteSection = styled.section`
  .wrapper {
    ${standardWrapper};
  }

  .quote {
    width: 100%;

    p {
      ${HIntroGreen};
      font-weight: bold;
    }
  }
`

export default Quote
