import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import BGImg from "gatsby-background-image"
import {
  colors,
  H1White,
  standardWrapper,
  HIntroWhite,
  Btn1Green,
} from "../../styles/helpers"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Link } from "gatsby"

const settings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  draggable: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 15000,
  centerPadding: "0",
  arrows: false,
  dots: true,
}

const HeroSlider = ({ data }) => {
  return (
    <HeroSliderStyled>
      <Slider {...settings}>
        {data.slider.map((slide, index) => {
          const heroImage = slide.image.localFile.childImageSharp.fluid
          return (
            <Slide key={index}>
              <div className="slideWrap">
                <div className="slideContent">
                  <div>
                    <h2>{slide.title}</h2>
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: slide.content }} />

                  {slide.buttonType === "external" ? (
                    <div>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={slide.buttonUrl}
                      >
                        {slide.buttonText}
                      </a>
                    </div>
                  ) : (
                    <div>
                      {slide.needsRefresh ? (
                        <button
                          onClick={() =>
                            window.location.replace(
                              `${process.env.GATSBY_BASE_URL}/${slide.buttonSlug}`
                            )
                          }
                        >
                          {slide.buttonText}
                        </button>
                      ) : (
                        <Link to={`/${slide.buttonSlug}`}>
                          {slide.buttonText}
                        </Link>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="slideOverlay" />
              <div className="slideImage">
                <BGImg tag="div" fluid={heroImage} alt={""} />
              </div>
            </Slide>
          )
        })}
      </Slider>
    </HeroSliderStyled>
  )
}

const HeroSliderStyled = styled.div`
  .slick-slider {
    position: relative;
  }

  .slick-dots {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    top: auto;
    transform: translateY(-50%);
    right: auto;
    bottom: -1rem;
    left: auto;

    @media (min-width: 1000px) {
      flex-direction: column;
      top: 50%;
      bottom: auto;
      left: 0;
      width: 5rem;
    }

    li {
      width: 4rem;
      height: 4rem;
      border: 0.1rem solid ${colors.white};

      button {
        width: 4rem;
        height: 4rem;
        color: ${colors.white};
        font-size: 1em;

        &::before {
          display: none;
        }
      }
    }

    li.slick-active {
      background-color: ${colors.colorAccent};
    }
  }
`

const Slide = styled.div`
  position: relative;
  width: 100%;
  height: 60rem;

  @media (min-width: 1025px) {
    height: 55rem;
  }

  .slideWrap {
    ${standardWrapper};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    position: relative;
    z-index: 10;
  }

  .slideContent {
    align-self: center;

    h2 {
      ${H1White};
    }

    ${HIntroWhite};
    p {
    }

    a,
    button {
      ${Btn1Green};
      background: transparent;
    }
  }

  .slideOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background: ${colors.colorPrimary};
    opacity: 0.8;
  }

  .slideImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    div {
      width: 100%;
      height: 100%;
    }
  }
`

export default HeroSlider
