import React from "react"
import styled from "styled-components"
import { H2Teal, standardWrapper, B1Slate } from "../../styles/helpers"

const SimpleContentBlock = ({ data }) => {
  return (
    <SimpleContentBlockSection>
      <div className="wrapper">
        <div>
          <h2>{data.title}</h2>
        </div>
        <div dangerouslySetInnerHTML={{ __html: data.content }} />
      </div>
    </SimpleContentBlockSection>
  )
}

const SimpleContentBlockSection = styled.section`
  margin: 2rem auto;

  .wrapper {
    ${standardWrapper};
  }

  h2 {
    ${H2Teal};
  }

  p {
    ${B1Slate};
  }
`

export default SimpleContentBlock
