import React from "react"

import HeroSlider from "./PageComponents/HeroSlider"
import ThreeColumns from "./PageComponents/ThreeColumns"
import ThreeColumnsGreen from "./PageComponents/ThreeColumnsGreen"
import CalloutButtons from "./PageComponents/CalloutButtons"
import ContentByStats from "./PageComponents/ContentByStats"
import Infographic from "./PageComponents/Infographic"
import TitleSection from "./PageComponents/TitleSection"
import SimpleContentBlock from "./PageComponents/SimpleContentBlock"
import ContentWithImageLinks from "./PageComponents/ContentWithImageLinks"
import Quote from "./PageComponents/Quote"
import CalloutButtonsImage from "./PageComponents/CalloutButtonsImage"
import ImageBesideContentEven from "./PageComponents/ImageBesideContentEven"
import ImageByWysiwyg from "./PageComponents/ImageByWysiwyg"
import HeroSectionOne from "./PageComponents/HeroSectionOne"
import WysiwygWithQuote from "./PageComponents/WysiwygWithQuote"
import WysiwygWithSubTitle from "./PageComponents/WysiwygWithSubTitle"
import BannerColorBg from "./PageComponents/BannerColorBg"
import ListWithContent from "./PageComponents/ListWithContent"
import SimpleContentImage from "./PageComponents/SimpleContentImage"
import DisplayBod from "./PageComponents/DisplayBod"
import DisplaySponsors from "./PageComponents/DisplaySponsors"
import DisplayCareers from "./PageComponents/DisplayCareers"
import HeroImage from "./PageComponents/HeroImage"
import RepeatableImageByContent from "./PageComponents/RepeatableImageByContent"
import RepeatableCircleContent from "./PageComponents/RepeatableCircleContent"
import Directory from "./PageComponents/Directory"
import DisplayNewsEvents from "./PageComponents/DisplayNewsEvents"
import ContactForm from "./PageComponents/ContactForm"
import MailChimp from "./PageComponents/MailChimp"
import MailChimpButton from "./PageComponents/MailChimpButton"
import WysiwygWithButton from "./PageComponents/WysiwygWithButton"
import EmbedCode from "./PageComponents/EmbedCode"
import CalloutButtonsDownload from "./PageComponents/CalloutButtonDownload"
import MapLocation from "./PageComponents/MapLocation"
import SideBySideItemList from "./PageComponents/SideBySideItemList"
import ThreeBlocksImages from "./PageComponents/ThreeBlocksImages"
import CardsRow from "./PageComponents/CardsRow"

const PageComponentGroups = props => {
  const { components } = props
  const allPageComponents =
    components?.acfMainTemplateFields?.pageComponents?.length > 0 ? (
      <>
        {components?.acfMainTemplateFields?.pageComponents.map(
          (component, index) => {
            switch (component?.fieldGroupName) {
              case "Page_Acfmaintemplatefields_PageComponents_TitleSection":
                return <TitleSection key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_Slider":
                return <HeroSlider key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_ThreeColumns":
                return <ThreeColumns key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_ThreeColumnsGreen":
                return <ThreeColumnsGreen key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_CalloutButtons":
                return <CalloutButtons key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_ContentByStats":
                return <ContentByStats key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_Infographic":
                return <Infographic key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_SimpleContentBlock":
                return <SimpleContentBlock key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_ContentWithImageLinks":
                return <ContentWithImageLinks key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_Quote":
                return <Quote key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_CalloutButtonsImage":
                return <CalloutButtonsImage key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_ImageBesideContentEven":
                return <ImageBesideContentEven key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_ImageByWysiwyg":
                return <ImageByWysiwyg key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_HeroSectionOne":
                return <HeroSectionOne key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_WysiwygWithQuote":
                return <WysiwygWithQuote key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_WysiwygWithSubTitle":
                return <WysiwygWithSubTitle key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_BannerColorBg":
                return <BannerColorBg key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_ListWithContent":
                return <ListWithContent key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_SimpleContentImage":
                return <SimpleContentImage key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_DisplayBod":
                return <DisplayBod key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_DisplaySponsors":
                return <DisplaySponsors key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_DisplayCareers":
                return <DisplayCareers key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_HeroImage":
                return <HeroImage key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_RepeatableImageByContent":
                return <RepeatableImageByContent key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_RepeatableCircleContent":
                return <RepeatableCircleContent key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_Directory":
                return <Directory key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_NewsAndEvents":
                return <DisplayNewsEvents key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_ContactForm":
                return <ContactForm key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_MailchimpForm":
                return <MailChimpButton key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_WysiwygWithButton":
                return <WysiwygWithButton key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_EmbedCode":
                return <EmbedCode key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_CalloutButtonsDownload":
                return <CalloutButtonsDownload key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_MapLocation":
                return <MapLocation key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_SideBySideItemsList":
                return <SideBySideItemList key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_ThreeBlocksImages":
                return <ThreeBlocksImages key={index} data={component} />

              case "Page_Acfmaintemplatefields_PageComponents_CardsRow":
                return <CardsRow key={index} data={component} />

              default:
                return (
                  <p>Cannot find this component {component.fieldGroupName}</p>
                )
            }
          }
        )}
      </>
    ) : (
      <p>This page has no content</p>
    )

  return <>{allPageComponents}</>
}

export default PageComponentGroups
