import React, { useEffect } from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { HIntroBlueberry, standardWrapper } from "../../styles/helpers"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const ImageBesideContentEven = ({ data }) => {
  const imageTopDisplay = getImage(
    data.imageTop.localFile.childImageSharp.gatsbyImageData
  )
  const imageTopAlt = data.imageTop.altText

  const imageBotDisplay = getImage(
    data.imageBottom.localFile.childImageSharp.gatsbyImageData
  )
  const imageBotAlt = data.imageBottom.altText

  useEffect(() => {
    const topContent = document.querySelector(".topContent")
    const topImage = document.querySelector(".topContent .image")
    const topPara = document.querySelector(".topContent .content")

    const botContent = document.querySelector(".bottomContent")
    const botImage = document.querySelector(".bottomContent .image")
    const botPara = document.querySelector(".bottomContent .content")

    gsap.set(topImage, {
      autoAlpha: 0,
      x: -200,
    })

    gsap.set(topPara, {
      autoAlpha: 0,
      x: 200,
    })

    gsap.set(botImage, {
      autoAlpha: 0,
      x: -200,
    })

    gsap.set(botPara, {
      autoAlpha: 0,
      x: 200,
    })

    gsap
      .timeline({
        scrollTrigger: {
          trigger: topContent,
          markers: false,
          start: "top 45%",
          toggleActions: "play none none none",
        },
      })
      .to([topImage, topPara], {
        autoAlpha: 1,
        x: 0,
        y: 0,
        ease: "power4.out",
        stagger: {
          amount: 0.5,
        },
      })

    gsap
      .timeline({
        scrollTrigger: {
          trigger: botContent,
          markers: false,
          start: "top 45%",
          toggleActions: "play none none none",
        },
      })
      .to([botImage, botPara], {
        autoAlpha: 1,
        x: 0,
        y: 0,
        ease: "power4.out",
        stagger: {
          amount: 0.5,
        },
      })
  }, [])

  return (
    <ImageBesideContentEvenSection>
      <div className="wrapper">
        <div className="topContent">
          <div className="image">
            <GatsbyImage
              image={imageTopDisplay}
              alt={imageTopAlt}
              layout="fixed"
            />
          </div>
          <div className="content">
            <h2>{data.contentTopTitle}</h2>
            <div dangerouslySetInnerHTML={{ __html: data.contentTop }} />
          </div>
        </div>
        <div className="bottomContent">
          <div className="image">
            <GatsbyImage
              image={imageBotDisplay}
              alt={imageBotAlt}
              layout="fixed"
            />
          </div>
          <div className="content">
            <h2>{data.contentBottomTitle}</h2>
            <div dangerouslySetInnerHTML={{ __html: data.contentBottom }} />
          </div>
        </div>
      </div>
    </ImageBesideContentEvenSection>
  )
}

const ImageBesideContentEvenSection = styled.section`
  .wrapper {
    ${standardWrapper};
  }

  .topContent,
  .bottomContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 4rem;

    .image {
      width: 100%;

      @media (min-width: 768px) {
        width: calc(50%);
      }
    }

    .content {
      width: 100%;

      @media (min-width: 768px) {
        width: calc(50%);
        padding: 5rem;
        padding-right: 7.5rem;
      }

      h2 {
        ${HIntroBlueberry};
        text-decoration: underline;
        text-transform: uppercase;
      }

      p {
        ${HIntroBlueberry};
      }
    }
  }
`

export default ImageBesideContentEven
