import React from "react"
import styled from "styled-components"
import { H1Blueberry, standardWrapper, HIntroGreen } from "../../styles/helpers"

import Wysiwyg from "./Wysiwyg"

const WysiwygWithSubTitle = ({ data }) => {
  return (
    <WysiwygWithSubTitleStyled>
      <div className="wrapper">
        <div className="title">
          <h2>{data.title}</h2>
        </div>
        <div className="subTitle">
          <h3>{data.subTitle}</h3>
        </div>
        <div className="wysiwyg">
          <Wysiwyg data={{ wysiwyg: data.wysiwyg }} />
        </div>
      </div>
    </WysiwygWithSubTitleStyled>
  )
}

const WysiwygWithSubTitleStyled = styled.section`
  .wrapper {
    ${standardWrapper};
  }

  .title {
    width: 100%;
    max-width: 90rem;
    text-align: center;

    h2 {
      ${H1Blueberry};
    }
  }

  .subTitle {
    width: 100%;
    max-width: 90rem;
    text-align: center;

    h3 {
      ${HIntroGreen};
      margin: 0;
    }
  }
`

export default WysiwygWithSubTitle
