import React from "react"
import styled from "styled-components"

import { standardWrapper } from "../../styles/helpers"
import Wysiwyg from "./Wysiwyg"
import SocialMedia from "./SocialMedia"

const MapLocation = ({ data }) => {
  return (
    <StyledDiv>
      <div className="wrapper">
        <div className="map">
          <Wysiwyg data={{ wysiwyg: data.mapLocationMapEmbedCode }} />
        </div>
        <div className="location">
          <Wysiwyg data={{ wysiwyg: data.mapLocationLocationWysiwyg }} />
          <SocialMedia darkMode={true} map={true} />
        </div>
      </div>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  .wrapper {
    ${standardWrapper};
    width: 100%;
  }

  .location {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(50% - 2rem);
      margin-left: 2rem;
    }
  }

  .map {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(50% - 2rem);
      margin-right: 2rem;
    }

    iframe {
      width: 100% !important;
    }
  }
`

export default MapLocation
