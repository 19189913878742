import React from "react"
import BGImg from "gatsby-background-image"

const NewArticle = ({ post, postDate, featuredImage }) => {
  return (
    <article>
      <div className="image">
        <div className="imageWrapper">
          <BGImg tag="div" fluid={featuredImage} />
        </div>
      </div>
      <div className="content">
        <div className="contentCats">
          <p>
            {post.node.categories.nodes.map((cat, index) => {
              return (
                <span key={index}>
                  {index !== 0 ? ", " : ""}
                  {cat.name}
                </span>
              )
            })}
          </p>
        </div>
        <div className="contentTitle">
          <h2>{post.node.title}</h2>
        </div>
        <div
          className="contentExcerpt"
          dangerouslySetInnerHTML={{
            __html: post.node.acfNewsEvents.excerpt,
          }}
        />

        <div className="contentDate">
          <p>{postDate}</p>
        </div>
      </div>
    </article>
  )
}

export default NewArticle
