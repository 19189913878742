import React from "react"
import styled from "styled-components"
import {
  Btn1Green,
  colors,
  H2Teal,
  standardWrapper,
} from "../../styles/helpers"

import Wysiwyg from "./Wysiwyg"

const WysiwygWithButton = ({ data }) => {
  return (
    <WysiwygWithButtonStyled bcactive={data.bcActive}>
      <div className="wrapper">
        {data.title && (
          <div className="title">
            <h2>{data.title}</h2>
          </div>
        )}
        <div className="wysiwyg">
          <Wysiwyg data={{ wysiwyg: data.wysiwyg }} />
        </div>
        <div className="button-link">
          <a target="_blank" rel="noreferrer" href={data.buttonUrl}>
            {data.buttonText}
          </a>
        </div>
      </div>
    </WysiwygWithButtonStyled>
  )
}

const WysiwygWithButtonStyled = styled.section`
  background-color: ${props =>
    props.bcactive ? colors.colorTertiary : "transparent"};

  .wrapper {
    ${standardWrapper};
  }

  .title {
    width: 100%;
    max-width: 90rem;
    text-align: left;

    h2 {
      ${H2Teal};
      margin-bottom: 0;
    }
  }

  .button-link {
    width: 100%;
    max-width: 90rem;
    text-align: left;

    a {
      ${Btn1Green};
      background-color: transparent;
    }
  }
`

export default WysiwygWithButton
