import React, { useState } from "react"
import styled from "styled-components"
import {
  B1Slate,
  Btn1Green,
  HIntroGreen,
  standardWrapper,
} from "../../styles/helpers"

import inputField from "../FormParts/styles/inputField"
import textareaField from "../FormParts/styles/textareaField"

import submitToServer from "../FormParts/functions/submitToServer"

import FormSuccess from "../FormParts/formModals/FormSuccess"
import FormSubmit from "../FormParts/formModals/FormSubmit"
import FormErrors from "../FormParts/formModals/FormErrors"

const ContactForm = ({ data }) => {
  const [formData, setFormData] = useState({
    yourName: "",
    phone: "",
    email: "",
    message: "",
  })

  const [formStatus, setFormStatus] = useState({
    submitting: false,
    errorWarnDisplay: false,
    success: false,
    errors: [],
  })

  const handleOnChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    })
  }

  const handleOnSubmit = async event => {
    event.preventDefault()
    setFormStatus({
      ...formStatus,
      submitting: true,
    })
    const formDataArray = Object.entries(formData)
    const bodyFormData = new FormData()
    formDataArray.forEach(field => {
      bodyFormData.append(field[0], field[1])
    })

    const response = await submitToServer(8, bodyFormData)

    if (!response.errors) {
      setFormStatus({
        ...formStatus,
        submitting: false,
        errorWarnDisplay: false,
        success: true,
        errors: [],
      })
    } else {
      setFormStatus({
        ...formStatus,
        submitting: false,
        errorWarnDisplay: true,
        success: false,
        errors: response.errorMessages,
      })
    }
  }

  const handleErrorModalClose = () => {
    setFormStatus({
      ...formStatus,
      submitting: false,
      errorWarnDisplay: false,
      success: false,
    })
  }

  const handleSuccessModalClose = () => {
    setFormStatus({
      ...formStatus,
      submitting: false,
      errorWarnDisplay: false,
      success: false,
      errors: [],
    })

    setFormData({
      yourName: "",
      phone: "",
      email: "",
      message: "",
    })
  }

  if (!data.displayContactForm) return null

  return (
    <ContactFormSection>
      <div className="wrapper">
        <div
          className="intro"
          dangerouslySetInnerHTML={{ __html: data.formIntro }}
        />

        <Form onSubmit={handleOnSubmit}>
          <fieldset>
            <legend id="legend-1">Your Contact Information</legend>
            <InputField>
              <label htmlFor="yourName">
                Your Name <span className="required">(required)</span>
                <span
                  className={`error-message${
                    formStatus.errors.findIndex(
                      error => error.idref === "yourName"
                    ) !== -1
                      ? " error-active"
                      : ""
                  }`}
                >
                  You must input a Name.
                </span>
                <input
                  name="yourName"
                  type="text"
                  value={formData.yourName}
                  id="yourName"
                  onChange={handleOnChange}
                  // aria-required="true"
                  // required
                />
              </label>
            </InputField>
            <InputField>
              <label htmlFor="email">
                Email <span className="required">(required)</span>
                <span
                  className={`error-message${
                    formStatus.errors.findIndex(
                      error => error.idref === "email"
                    ) !== -1
                      ? " error-active"
                      : ""
                  }`}
                >
                  You must input an email.
                </span>
                <input
                  name="email"
                  type="email"
                  value={formData.email}
                  id="email"
                  onChange={handleOnChange}
                  // aria-required="true"
                  // required
                />
              </label>
            </InputField>
            <InputField>
              <label htmlFor="phone">
                Phone Number <span className="required">(required)</span>
                <span
                  className={`error-message${
                    formStatus.errors.findIndex(
                      error => error.idref === "phone"
                    ) !== -1
                      ? " error-active"
                      : ""
                  }`}
                >
                  You must input a phone number.
                </span>
                <input
                  name="phone"
                  type="text"
                  value={formData.phone}
                  id="phone"
                  onChange={handleOnChange}
                  // aria-required="true"
                  // required
                />
              </label>
            </InputField>
          </fieldset>
          <fieldset>
            <legend id="legend-2">Message</legend>
            <TextareaField>
              <label htmlFor="message">
                Add a message <span className="required">(required)</span>
                <span
                  className={`error-message${
                    formStatus.errors.findIndex(
                      error => error.idref === "message"
                    ) !== -1
                      ? " error-active"
                      : ""
                  }`}
                >
                  Add a message
                </span>
                <textarea
                  name="message"
                  value={formData.message}
                  id="message"
                  onChange={handleOnChange}
                  rows={6}
                  // aria-required="true"
                  // required
                />
              </label>
            </TextareaField>
          </fieldset>
          <SubmitButton>
            <button type="submit">Submit</button>
          </SubmitButton>
        </Form>
      </div>
      <FormSubmit isActive={formStatus.submitting} />
      <FormSuccess
        isActive={formStatus.success}
        handleClose={handleSuccessModalClose}
      />
      <FormErrors
        isActive={formStatus.errorWarnDisplay}
        handleClose={handleErrorModalClose}
      />
    </ContactFormSection>
  )
}

const ContactFormSection = styled.section`
  .wrapper {
    ${standardWrapper};
  }

  .intro {
    width: 100%;
    max-width: 90rem;
    text-align: left;

    p {
      ${HIntroGreen};
    }
  }
`

const Form = styled.form`
  width: 100%;
  max-width: 60rem;
  margin-bottom: 2.5rem;

  .privacyStatement {
    p {
      ${B1Slate};
    }
  }

  fieldset {
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
  }

  legend {
    ${B1Slate};
  }
`

const InputField = styled.div`
  ${inputField};
`

const TextareaField = styled.div`
  ${textareaField};
`

const SubmitButton = styled.div`
  button {
    ${Btn1Green};
  }
`

export default ContactForm
