import React from "react"
import styled from "styled-components"
import { standardWrapper } from "../../styles/helpers"
import { Helmet } from "react-helmet"

const DonateForm = () => {
  return (
    <DonateFormStyled>
      <div className="wrapper">
        <div className="wysiwyg-embed">
          <Helmet>
            <script
              id="ch_cdn_embed"
              type="text/javascript"
              src="https://www.canadahelps.org/secure/js/cdf_embed.js"
              charset="utf-8"
              data-language="en"
              data-page-id="3650"
              data-root-url="https://www.canadahelps.org"
              data-formtype="1"
              data-cfasync="false"
            ></script>
          </Helmet>
        </div>
      </div>
    </DonateFormStyled>
  )
}

const DonateFormStyled = styled.div`
  width: 100%;

  .wrapper {
    ${standardWrapper};
  }

  .wysiwyg-embed {
    width: 100%;
    min-height: 10rem;
  }
`

export default DonateForm
